import React, { useState } from "react";
import ChooseSubscription from "../ChooseSubscription";
import SubscriptionCards from "../SubscriptionCards";

const ShowPricingSubscription = ({ pricingPageData }) => {
  const [subscriptionValue, setSubscriptionValue] = useState("monthly");
  const [selectedPlan, setSelectedPlan] = useState(1);
  return (
    <div>
      <section className="mb-8 pt-8">
        <ChooseSubscription
          subscriptionValue={subscriptionValue}
          setSubscriptionValue={setSubscriptionValue}
          pageData={pricingPageData}
        />
      </section>

      <section className="mb-8 xl:mb-32">
        <SubscriptionCards
          subscriptionValue={subscriptionValue}
          setSubscriptionValue={setSubscriptionValue}
          selectedPlan={selectedPlan}
          setSelectedPlan={setSelectedPlan}
          pageData={pricingPageData}
        />
      </section>
    </div>
  );
};

export default ShowPricingSubscription;
