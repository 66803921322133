import React, { useContext, useEffect, useState } from "react";
import SubscriptionFaq from "../components/SubscriptionFaq";
import { AppDataContext } from "../context/appData";
import ShowPricingSubscription from "../components/ShowPricingSubscription";

const Pricing = () => {
  // app data context
  const {
    state: { appData },
  } = useContext(AppDataContext);

  const [pricingPageData, setPricingPageData] = useState();

  useEffect(() => {
    setPricingPageData(appData?.pricingPageData);
  }, [appData?.pricingPageData]);

  return (
    <div className="relative bg-white dark:bg-darkBgColor1">
      <ShowPricingSubscription pricingPageData={pricingPageData} />
      <div className="absolute bottom-[55%] transform left-0 bg-neonColorBlue2 blur-[100px] right-1/2 h-[30%]" />
      <div className="absolute bottom-[55%] transform right-0 bg-neonPurpleColor blur-[100px] left-1/2 h-[30%]" />

      <section className="pb-8 xl:pb-32">
        <SubscriptionFaq pageData={pricingPageData} />
      </section>
    </div>
  );
};

export default Pricing;
