import React, { useContext } from "react";
import ReactMarkdown from "react-markdown";
import MathJax from "react-mathjax2";
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { materialLight } from "react-syntax-highlighter/dist/esm/styles/prism";
// import { Line, Bar, Pie } from "react-chartjs-2";
import { DarkModeContext } from "../../context/darkModeContext";
import "katex/dist/katex.min.css";
import "./Markdown.css";

const NewMathRender = ({ markdown }) => {
  const { darkMode } = useContext(DarkModeContext);
  // Replace \[ with $$ and \] with $$ to ensure compatibility
  const processedText = markdown
    .replace(/\\\[/g, "$$$") // Replace all occurrences of \[ with $$
    .replace(/\\\]/g, "$$$") // Replace all occurrences of \] with $$
    .replace(/\\\(/g, "$$$") // Replace all occurrences of \( with $$
    .replace(/\\\)/g, "$$$"); // Replace all occurrences of \) with $$

  const remarkMathOptions = {
    singleDollarTextMath: false,
  };

  const components = {
    code({ node, inline, className, children, ...props }) {
      const match = /language-(\w+)/.exec(className || "");
      return !inline && match ? (
        <SyntaxHighlighter
          style={darkMode ? a11yDark : materialLight}
          language={match[1]}
          PreTag="div"
          customStyle={{
            borderRadius: "10px",
          }}
          wrapLongLines
          {...props}
        >
          {String(children).replace(/\n$/, "")}
        </SyntaxHighlighter>
      ) : (
        <code className={className} {...props}>
          {children}
        </code>
      );
    },
  };

  return (
    <div className="markdown-container text-sm break-words">
      <MathJax.Context
        input="ascii"
        script="https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.2/MathJax.js?config=AM_HTMLorMML"
        options={{
          asciimath2jax: {
            useMathMLspacing: false,
            delimiters: [
              ["$$", "$$"],
              ["$", "$"],
              ["\\(", "\\)"],
            ],
          },
        }}
      >
        <MathJax.Text
          className="w-full"
          text={
            <ReactMarkdown
              children={processedText}
              remarkPlugins={[[remarkMath, remarkMathOptions], remarkGfm]}
              rehypePlugins={[rehypeRaw, rehypeKatex]}
              components={components}
            />
          }
        />
      </MathJax.Context>
    </div>
  );
};

export default NewMathRender;
